import {
  GET_PEDIDO_ACTIVO,
  POST_LINEA_PEDIDO,
  POST_LINEAS_PEDIDO,
  DELETE_LINEA_PEDIDO,
  INTEGRAR_PEDIDO,
  DELETE_PEDIDO,
  CERRAR_PEDIDO,
  FETCH_PEDIDO_ACTIVO,
  FETCH_PEDIDO_ACTIVO_FAIL,
  INTEGRANDO_PEDIDO,
  FETCH_TEXTOS_VENTAS,
  GET_TEXTOS_VENTAS,
  SET_ID_PEDIDO_SELECCIONADO,
  POST_PEDIDO,
  INTEGRAR_PEDIDO_FAIL,
} from "../actions/types.js";

const initialState = {
  pedido: {},
  serie_pedido: "",
  num_pedido: "",
  numero_serie_pres: "",
  numero_pres: "",
  ejercicio: "",
  texto_venta: {},
  isLoading: false,
  id_pedido_seleccionado: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PEDIDO_ACTIVO:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PEDIDO_ACTIVO:
      return {
        ...state,
        pedido: action.payload,
        serie_pedido: "",
        num_pedido: "",
        numero_serie_pres: "",
        numero_pres: "",
        ejercicio: "",
        isLoading: false,
      };
    case POST_LINEA_PEDIDO:
      let pedido_plp = state.pedido;
      pedido_plp.lineas = [...pedido_plp.lineas, action.payload];
      return {
        ...state,
        pedido: { ...state.pedido, pedido_plp },
        isLoading: false,
      };
    case POST_LINEAS_PEDIDO:
      let pedido_plp2 = state.pedido;
      pedido_plp2.lineas = pedido_plp2.lineas.concat(action.payload);
      return {
        ...state,
        pedido: { ...state.pedido, pedido_plp2 },
        isLoading: false,
      };
    case DELETE_LINEA_PEDIDO:
      let pedido_dlp = state.pedido;
      pedido_dlp.lineas = pedido_dlp.lineas.filter(
        (linea) => linea.id !== Number.parseInt(action.payload)
      );
      return {
        ...state,
        pedido: { ...state.pedido, pedido_dlp },
        isLoading: false,
      };
    case INTEGRANDO_PEDIDO:
      return {
        ...state,
        isLoading: true,
      };

    case INTEGRAR_PEDIDO:
      return {
        ...state,
        pedido: action.payload,
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      };
    case CERRAR_PEDIDO:
      return {
        ...state,
        pedido: {},
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      };
    case DELETE_PEDIDO:
      return {
        ...state,
        pedido: {},
        isLoading: false,
      };
    case INTEGRAR_PEDIDO_FAIL:
    case FETCH_PEDIDO_ACTIVO_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: {},
        isLoading: true,
      };
    case GET_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: action.payload,
        isLoading: false,
      };
    case SET_ID_PEDIDO_SELECCIONADO:
      return {
        ...state,
        id_pedido_seleccionado: action.payload,
      };
    case POST_PEDIDO:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
