import axios from "../axiosConfig";
import { createMessage, returnErrors } from "./messages";
import { tokenConfig } from "./auth";
import {
  GET_PEDIDO_ACTIVO,
  INTEGRAR_PEDIDO,
  DELETE_PEDIDO,
  BORRA_HASH,
  CERRAR_PEDIDO,
  FETCH_PEDIDO_ACTIVO,
  FETCH_PEDIDO_ACTIVO_FAIL,
  INTEGRANDO_PEDIDO,
  GET_TEXTOS_VENTAS,
  FETCH_TEXTOS_VENTAS,
  SET_ID_PEDIDO_SELECCIONADO,
  POST_PEDIDO,
  DELETE_PEDIDO_PENDIENTE,
  INTEGRAR_PEDIDO_FAIL,
} from "./types";

// GET PEDIDO ACTIVO
export const getPedidoActivo =
  (hash_invitado = "", recalcular_pedido = "S", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    // const refrescar = refreshToken(dispatch);
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    axios
      .get(
        `/pedidos/?activo=S&inv=${hash_invitado}&recalcular=${recalcular_pedido}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });

        const invitado = getState().auth.invitado;
        if (!invitado) {
          dispatch({ type: BORRA_HASH });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        //
        // if (err.response.status === 401) {
        //   refrescar();
        // }
      });
  };

// ELIMINAR EL PEDIDO
export const eliminarPedido =
  (id, hash_invitado = "", id_pedido_seleccionado = "", origen = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    if (origen === "") {
      axios
        .delete(
          `/pedidos/${id}/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
          tokenConfig(getState)
        )
        .then((res) => {
          dispatch(createMessage({ pedidoEliminado: "Pedido Eliminado" }));
          dispatch({
            type: DELETE_PEDIDO,
            payload: id,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            returnErrors(
              err.response.data !== undefined
                ? err.response.data
                : "No se ha podido eliminar el pedido en este momento.",
              err.response.status
            )
          );
          dispatch({
            type: FETCH_PEDIDO_ACTIVO_FAIL,
          });
        });
    } else if ((origen = "pendientes")) {
      axios
        .delete(
          `/pedidos/${id}/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
          tokenConfig(getState)
        )
        .then((res) => {
          dispatch(createMessage({ pedidoEliminado: "Pedido Eliminado" }));
          dispatch({
            type: DELETE_PEDIDO_PENDIENTE,
            payload: id,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            returnErrors(
              err.response.data !== undefined
                ? err.response.data
                : "No se ha podido eliminar el pedido en este momento.",
              err.response.status
            )
          );
          dispatch({
            type: FETCH_PEDIDO_ACTIVO_FAIL,
          });
        });
    }
  };

// INSERTAR LINEA AL PEDIDO
export const postLinea =
  (linea, hash_invitado = "", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    const body = JSON.stringify(linea);

    axios
      .post(
        `/lineas/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
        body,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch(
          createMessage({
            lineaAnadida: "Se ha añadido el artículo a su carrito",
          })
        );
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(
          returnErrors(
            err.response.data !== undefined
              ? err.response.data
              : "No se ha podido añadir el artículo a su pedido en este momento.",
            err.response.status
          )
        );
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

export const postLineas =
  (_lineas, hash_invitado = "", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    const body = { lineas: _lineas };

    axios
      .post(
        `/lineas/createmany/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
        body,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch(
          createMessage({
            lineaAnadida: "Se han añadido los artículos a su carrito",
          })
        );
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          returnErrors(
            "No se han podido añadir los artículos a su pedido en este momento.",
            err.response.status
          )
        );
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

// DELETE LINEA
export const deleteLinea =
  (id, hash_invitado = "", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });
    axios
      .delete(
        `/lineas/${id}/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch(createMessage({ lineaEliminada: "Línea Eliminada" }));
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(
          returnErrors(
            "No se ha podido eliminar la línea de su pedido en este momento.",
            err.response.status
          )
        );
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

// INTEGRAR PEDIDO
export const integraPedido =
  (
    domicilio,
    observaciones,
    forma_envio,
    almacen_recogida,
    ruta,
    forma_pago,
    pedido,
    recalcular_pedido,
    id_pedido_seleccionado = ""
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: INTEGRANDO_PEDIDO,
    });

    const body = JSON.stringify({
      domicilio,
      observaciones,
      forma_envio,
      almacen_recogida,
      ruta,
      forma_pago,
      pedido,
    });

    axios
      .post(
        `/integrapedido/?recalcular=${recalcular_pedido}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
        body,
        tokenConfig(getState)
      )
      .then((res) => {
        if (res.data.estado_pedido === "D") {
          dispatch({
            type: CERRAR_PEDIDO,
            payload: res.data,
          });
        } else {
          dispatch({
            type: INTEGRAR_PEDIDO,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          dispatch(
            returnErrors(
              "El pedido ha sufrido cambios, por favor, revíselo e inténtelo de nuevo",
              err.response.status
            )
          );
          dispatch({
            type: GET_PEDIDO_ACTIVO,
            payload: err.response.data,
          });
          console.log(err);
        } else {
          dispatch({
            type: INTEGRAR_PEDIDO_FAIL,
          });
          dispatch({
            type: GET_PEDIDO_ACTIVO,
            payload: err.response.data,
          });
          console.log(err);
        }
      });
  };

export const actualizaPedido =
  (
    domicilio,
    forma_envio,
    almacen_recogida,
    ruta,
    hash_invitado = "",
    tipo_pedido = {},
    id_pedido_seleccionado = ""
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });

    const body = JSON.stringify({
      domicilio,
      forma_envio,
      almacen_recogida,
      ruta,
      tipo_pedido,
    });

    axios
      .post(
        `/actualizapedido/?inv=${hash_invitado}&id_pedido_seleccionado=${id_pedido_seleccionado}`,
        body,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
        dispatch(
          createMessage({
            pedidoIntegrado: "Pedido actualizado",
          })
        );
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

export const cerrarPedido = (pedido) => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDO_ACTIVO,
  });
  const body = JSON.stringify({
    pedido,
  });

  axios
    .post("/actualizapedido/cierrapedido/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CERRAR_PEDIDO,
        payload: pedido,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: FETCH_PEDIDO_ACTIVO_FAIL,
      });
      console.log(err);
    });
};

export const calculaPortesPedido =
  (hash_invitado = "", id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_ACTIVO,
    });

    axios
      .get(
        (hash_invitado = "", id_pedido_seleccionado = "") =>
          `/pedidos/calculaportes/?inv=${hash_invitado}&id_pedido_seleccionado${id_pedido_seleccionado}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: FETCH_PEDIDO_ACTIVO_FAIL,
        });
        console.log(err);
      });
  };

export const getTextosVentas =
  (organizacion_comercial, presupuesto, idioma) => (dispatch, getState) => {
    dispatch({
      type: FETCH_TEXTOS_VENTAS,
    });
    axios
      .get(
        `/textosventas/?organizacion_comercial=${organizacion_comercial}&presupuesto=${presupuesto}&idioma=${idioma}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TEXTOS_VENTAS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const setIdPedidoSeleccionado =
  (id_pedido_seleccionado) => (dispatch, getState) => {
    dispatch({
      type: SET_ID_PEDIDO_SELECCIONADO,
      payload: id_pedido_seleccionado,
    });
  };

export const nuevoPedido = () => (dispatch, getState) => {
  axios
    .post(`/pedidos/`, {}, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: POST_PEDIDO,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
