import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPrecio } from "../../actions/precio";

export class Precio extends Component {
  state = {};

  static propTypes = {
    precio: PropTypes.object.isRequired,
    getPrecio: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.precio[this.props.codigo_articulo] === undefined) {
      this.props.getPrecio(
        this.props.codigo_articulo,
        1,
        this.props.id_pedido_seleccionado,
        this.props.pedido.domicilio_envio
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.precio[this.props.codigo_articulo] === undefined) {
      if (prevProps.codigo_articulo !== this.props.codigo_articulo) {
        this.props.getPrecio(
          this.props.codigo_articulo,
          1,
          this.props.id_pedido_seleccionado,
          this.props.pedido.domicilio_envio
        );
      }
    }
  }

  render() {
    if (
      this.props.precio[this.props.codigo_articulo] === undefined ||
      this.props.precio[this.props.codigo_articulo].isLoading
    ) {
      return (
        <Fragment>
          <img
            className="loader-precio"
            src="/assets/img/loader2.gif"
            data-src="/assets/img/loader2.gif"
            alt="Cargando..."
          />
        </Fragment>
      );
    } else if (this.props.precio[this.props.codigo_articulo].error) {
      return (
        <Fragment>
          <span>Precio no disponible</span>
        </Fragment>
      );
    } else if (this.props.precio[this.props.codigo_articulo] !== undefined) {
      return (
        <Fragment>
          <meta
            itemProp="priceCurrency"
            content={
              this.props.precio[this.props.codigo_articulo].data.divisa_precio
            }
          />
          <meta
            itemProp="price"
            content={Number.parseFloat(
              this.props.precio[this.props.codigo_articulo].data.precio_neto /
                this.props.precio[this.props.codigo_articulo].data
                  .cantidad_precio
            ).toFixed(2)}
          />
          <span className="etiqueta-precio">
            {this.props.precio[this.props.codigo_articulo].data.precio_neto
              ? new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency:
                    this.props.precio[this.props.codigo_articulo].data
                      .divisa_precio,
                }).format(
                  Number.parseFloat(
                    this.props.precio[this.props.codigo_articulo].data
                      .precio_neto /
                      this.props.precio[this.props.codigo_articulo].data
                        .cantidad_precio
                  ).toFixed(2)
                )
              : ""}

            {!Array.isArray(
              this.props.precio[this.props.codigo_articulo].data
                .impuesto_articulo
            ) &&
            this.props.precio[this.props.codigo_articulo].data
              .impuesto_articulo !== null &&
            this.props.mostrar_iva !== "N"
              ? " + " +
                this.props.precio[this.props.codigo_articulo].data
                  .impuesto_articulo +
                "% IVA"
              : ""}

            {typeof this.props.precio[this.props.codigo_articulo]
              .presentacion === "string" &&
            this.props.precio[this.props.codigo_articulo].data.presentacion !==
              ""
              ? "/" +
                this.props.precio[this.props.codigo_articulo].data.presentacion
              : ""}
          </span>

          {this.props.mostrar_iva == "N" && this.props.origen == "articulo"
            ? <div className="tt-impuestos">
              {"+ " + this.props.precio[this.props.codigo_articulo].data
              .impuesto_articulo + "% IVA"}
              </div>
            : ""}
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  precio: state.precio.precio,
  locale: state.precio.locale,
  auth: state.auth,
  presentacion: state.presentaciones.presentacion,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { getPrecio })(Precio);
