export const ordenarMenu = (a, b) => {
  if (a.orden < b.orden) {
    return -1;
  } else if (a.orden > b.orden) {
    return 1;
  } else {
    return 0;
  }
};

export const ordenarLista = (a, b) => {
  if (a.codigo_lista < b.codigo_lista) {
    return -1;
  } else if (a.codigo_lista > b.codigo_lista) {
    return 1;
  } else {
    return 0;
  }
};


export const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};
