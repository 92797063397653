import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import config from "../../config";

export class Footer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
  };

  render() {
    const { isAuthenticated } = this.props.auth;
    const { portal } = this.props;
    let menus = this.props.menus;

    if (
      menus !== undefined &&
      menus.length > 0 &&
      this.props.auth.user !== null &&
      this.props.auth.user.puede_crear_usuarios !== "S"
    ) {
      menus = menus.filter((menu) => {
        return menu.programa !== "registroHijos";
      });
    }

    const enlacesPublicos = (
      <Fragment>
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              menu.hijos.sort(this.ordenarMenu);
              return menu.visible === "S" &&
                menu.nombre !== "CARRITO" &&
                menu.nombre !== "USUARIO" ? (
                <Fragment key={menu.id}>
                  {menu.hijos.length > 0 ? (
                    <Fragment>
                      {menu.hijos.map((hijo) => {
                        return hijo.visible === "S" ? (
                          <li key={hijo.id}>
                            <Link
                              to={
                                "/" +
                                hijo.programa +
                                (hijo.camino !== null ? "/" + hijo.camino : "")
                              }
                            >
                              {hijo.nombre}
                            </Link>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </Fragment>
                  ) : (
                    <li key={menu.id}>
                      <Link
                        to={
                          "/" +
                          menu.programa +
                          (menu.camino !== null ? "/" + menu.camino : "")
                        }
                      >
                        {menu.nombre}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ) : (
                ""
              );
            })
          : ""}
        <li key={"configCookies"}>
          <Link to={"/configurarCookies"}>CONFIGURAR COOKIES</Link>
        </li>
      </Fragment>
    );

    const enlacesPrivados = (
      <Fragment>
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              menu.hijos.sort(this.ordenarMenu);
              return menu.visible === "S" &&
                menu.nombre !== "CARRITO" &&
                menu.nombre !== "USUARIO" ? (
                <Fragment key={menu.id}>
                  {menu.hijos.length > 0 ? (
                    <Fragment>
                      {menu.hijos.map((hijo) => {
                        return hijo.visible === "S" ? (
                          <li key={hijo.id}>
                            <Link
                              to={
                                "/" +
                                hijo.programa +
                                (hijo.camino !== null ? "/" + hijo.camino : "")
                              }
                            >
                              {hijo.nombre}
                            </Link>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </Fragment>
                  ) : (
                    <li key={menu.id}>
                      <Link
                        to={
                          "/" +
                          menu.programa +
                          (menu.camino !== null ? "/" + menu.camino : "")
                        }
                      >
                        {menu.nombre}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ) : (
                ""
              );
            })
          : ""}
        <li key={"configCookies"}>
          <Link to={"/configurarCookies"}>CONFIGURAR COOKIES</Link>
        </li>
      </Fragment>
    );

    const contenido = (
      <footer className="f-mobile-dark">
        <div className="tt-footer-col tt-color-scheme-01">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 col-xl-4">
                <div className="tt-mobile-collapse">
                  <h4 className="tt-collapse-title">CONTENIDO</h4>
                  <div className="tt-collapse-content">
                    <ul className="tt-list">
                      <li>
                        <Link to="/">Inicio</Link>
                      </li>
                      {enlacesPublicos}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-xl-4">
                {config.contacto.direccion !== "" ||
                config.contacto.telefono !== "" ||
                config.contacto.email !== "" ||
                config.contacto.horario !== "" ? (
                  <div className="tt-mobile-collapse">
                    <h4 className="tt-collapse-title">CONTACTO</h4>
                    <div className="tt-collapse-content">
                      <address>
                        {config.contacto.direccion !== "" ? (
                          <p>
                            <span>Dirección:</span> {config.contacto.direccion}
                          </p>
                        ) : (
                          ""
                        )}
                        {config.contacto.telefono !== "" ? (
                          <p>
                            <span>Teléfono:</span> {config.contacto.telefono}
                          </p>
                        ) : (
                          ""
                        )}
                        {config.contacto.horario !== "" ? (
                          <p>
                            <span>Horario:</span> {config.contacto.horario}
                          </p>
                        ) : (
                          ""
                        )}
                        {config.contacto.email !== "" ? (
                          <p>
                            <span>E-mail:</span>{" "}
                            <a href={config.contacto.email}>
                              {config.contacto.email}
                            </a>
                          </p>
                        ) : (
                          ""
                        )}
                      </address>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="tt-social-icon">
                  {config.redes_sociales.facebook !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.facebook}
                        target="blank"
                        className="icon-g-64"
                      >
                        <span className="ocultar">Facebook</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.twitter !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.twitter}
                        target="blank"
                        className="icon-h-58"
                      >
                        <span className="ocultar">Twitter</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.google_plus !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.google_plus}
                        target="blank"
                        className="icon-g-66"
                      >
                        <span className="ocultar">Google Plus</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.instagram !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.instagram}
                        target="blank"
                        className="icon-g-67"
                      >
                        <span className="ocultar">Instagram</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.linkedin !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.linkedin}
                        target="blank"
                        className="icon-g-70"
                      >
                        <span className="ocultar">Linkedin</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.coorporativa !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.coorporativa}
                        target="blank"
                        className="icon-e-40"
                      >
                        <span className="ocultar">Web coorporativa</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="col-md-6 col-lg-4 col-xl-4">
                <div className="tt-mobile-collapse">
                  <h4 className="tt-collapse-title">SECCIONES</h4>
                  <div className="tt-collapse-content">
                    <ul className="tt-list">{enlacesPrivados}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tt-footer-custom tt-color-scheme-04">
          <div className="container">
            <div className="tt-row">
              <div className="tt-col-left">
                <div className="tt-col-item tt-logo-col">
                  <Link to={"/"} className="tt-logo tt-logo-alignment">
                    {portal.logo !== null ? (
                      <img
                        src={"data:image/png;base64, " + portal.logo}
                        alt=""
                        className="loading"
                        data-was-processed="true"
                      />
                    ) : (
                      <span className="tt-title">{portal.descripcion}</span>
                    )}
                  </Link>
                </div>
                <div className="tt-col-item">
                  <div className="tt-box-copyright">
                    &copy; {config.copyright !== "" ? config.copyright : ""}{" "}
                    Todos los derechos reservados.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );

    return (
      <Fragment>
        {config.footer.publico ? contenido : isAuthenticated ? contenido : ""}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  portal: state.portal.portal,
  menus: state.portal.menus,
});

export default connect(mapStateToProps, { logout })(Footer);
