import React, { Component, Fragment, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { sendConversation } from "../../actions/expedientes";
import { createMessage } from "../../actions/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
export class NuevaConversacion extends Component {
  static propTypes = {
    tipo_expediente: PropTypes.string.isRequired,
    cerrarNuevo: PropTypes.func.isRequired,
  };

  state = {
    filesAux: [],
    fileInput: React.createRef(),
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let valores = {};
    let expediente = {};

    //Lectura de ficheros
    let readers = [];
    const readFile = (file) => {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
          resolve(fr.result);
        };

        fr.onerror = function () {
          reject(fr);
        };

        fr.readAsBinaryString(file);
      });
    };

    let comentarioss = e.currentTarget.elements["comentarios"].value;
    let descripcion_expediente11 =
      e.currentTarget.elements["descripcion_expediente1"].value;
    let comentarios0022 = e.currentTarget.elements["comentarios002"].value;

    if (this.state.filesAux !== undefined) {
      for (let index = 0; index < this.state.filesAux?.length; index++) {
        let selectedFile = this.state.filesAux?.item(index);
        if (selectedFile !== null && selectedFile !== undefined) {
          readers.push(readFile(selectedFile));
        }
      }
      Promise.all(readers).then((values) => {
        let fileArray = [];

        for (let index = 0; index < this.state.filesAux.length; index++) {
          let selectedFile = this.state.filesAux.item(index);
          if (selectedFile !== null && selectedFile !== undefined) {
            let fileName = selectedFile?.name;
            let fileType = selectedFile?.type;
            let contenido = btoa(values[index]);
            fileArray.push({
              nombre: fileName,
              tipo: fileType,
              contenido: contenido,
            });
          }
        }

        expediente = {
          comentarios: comentarioss,
          descripcion_expediente1: descripcion_expediente11,
          itema003: "",
          comentarios002: comentarios0022,
          ficheros: fileArray?.length > 0 ? fileArray : [],
          itemn001: fileArray?.length > 0 ? fileArray?.length : 0,
        };

        this.props.sendConversation(expediente);
      });
    } else {
      expediente = {
        comentarios: comentarioss,
        descripcion_expediente1: descripcion_expediente11,
        itema003: "",
        comentarios002: comentarios0022,
        ficheros: [],
        itemn001: 0,
      };

      this.props.sendConversation(expediente);
    }
    this.props.cerrarNuevo();
  };

  onSelectFile = () => {
    if (
      this.state.fileInput.current.files.length !== undefined &&
      this.state.fileInput.current.files.length > 0
    ) {
      this.setState({ filesAux: this.state.fileInput.current.files });
    }
  };

  cerrarFormulario = () => {
    this.props.cerrarNuevo();
  };

  handleClick = () => {
    this.state.fileInput.current.click();
  };

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">
                  NUEVA CONVERSACION
                </h1>
                <div className="row justify-content-center">
                  <div className="form-default justify-content-center">
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label>Titulo</label>
                        <input
                          className="form-control"
                          type="text"
                          name="descripcion_expediente1"
                          placeholder="Titulo"
                          required="S"
                          onChange={this.onChange}
                          value={this.state["descripcion_expediente1"]}
                        />
                      </div>
                      <div className="form-group">
                        <label>Cuerpo</label>
                        <input
                          className="form-control"
                          type="text"
                          name="comentarios"
                          placeholder="Cuerpo"
                          required="S"
                          onChange={this.onChange}
                          value={this.state["comentarios"]}
                        />
                      </div>

                      <div className="form-group">
                        <label>Comentarios</label>
                        <input
                          className="form-control"
                          type="text"
                          name="comentarios002"
                          placeholder="comentarios"
                          required="S"
                          onChange={this.onChange}
                          value={this.state["comentarios002"]}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          ref={this.state.fileInput}
                          hidden
                          type="file"
                          multiple={true}
                          onChange={(ev) => {
                            this.onSelectFile(ev.nativeEvent.target);
                          }}
                        />
                        <i
                          className="bi bi-archive-fill"
                          onClick={this.handleClick}
                        >
                          <FontAwesomeIcon icon={faUpload} />
                        </i>
                        {this.state.filesAux !== undefined &&
                        this.state.filesAux.length > 0 ? (
                          <label color="tertiary" className="texto1ad">
                            {this.state.filesAux.length} archivos seleccionados
                          </label>
                        ) : (
                          <label color="tertiary" className="texto1ad">
                            Ningún archivo seleccionado
                          </label>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-auto mr-auto">
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Enviar
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={this.cerrarFormulario}
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  sendConversation,
  createMessage,
})(NuevaConversacion);
