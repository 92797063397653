import { FETCH_PRESENTACIONES, GET_PRESENTACIONES, SET_PRESENTACION, LIMPIAR_PRESENTACION } from "../actions/types.js";

const initialState = {
  presentaciones: {},
  presentacion: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  let lista_presentaciones;
  switch (action.type) {
    case FETCH_PRESENTACIONES:
      lista_presentaciones = JSON.parse(JSON.stringify(state.presentaciones));
      lista_presentaciones[action.articulo] = [];
      return {
        ...state,
        presentaciones: lista_presentaciones,
        isLoading: true,
      };
    case GET_PRESENTACIONES:
      lista_presentaciones = JSON.parse(JSON.stringify(state.presentaciones));

      lista_presentaciones[action.articulo] = action.payload;
      return {
        ...state,
        presentaciones: lista_presentaciones,
        isLoading: false,
      };
    case SET_PRESENTACION:
      return {
        ...state,
        presentacion: action.payload,
        isLoading: false,
      };
      case LIMPIAR_PRESENTACION:
        return {
          ...state,
          presentacion: {},
          isLoading: false,
        };
    default:
      return state;
  }
}
