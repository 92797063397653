import { combineReducers } from "redux";
import articulos from "./articulos";
import articulo from "./articulo";
import pedidos from "./pedidos";
import informes from "./informes";
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import loader from "./loader";
import portal from "./portal";
import precio from "./precio";
import listasCompra from "./listasCompra";
import descripcion from "./descripcion";
import modalGenerica from "./modalGenerica";
import pedidosAnteriores from "./pedidosAnteriores";
import pedidosPendientes from "./pedidosPendientes";
import contacto from "./contacto";
import listadoArticulos from "./listadoArticulos";
import avisos from "./avisos";
import formasEnvio from "./formasEnvio";
import domiciliosEnvio from "./domiciliosEnvio";
import almacenes from "./almacenes";
import rutas from "./rutas";
import galeria from "./galeria";
import widgets from "./widgets";
import informesFiltroLov from "./informesFiltroLov";
import provincias from "./provincias";
import estados from "./estados";
import expedientes from "./expedientes";
import banners from "./banners";
import tiposNif from "./tiposNif";
import imagen from "./imagen";
import migas from "./migas";
import agrupaciones from "./agrupaciones";
import tiras from "./tiras";
import formasPago from "./formasPago";
import modular from "./modular";
import tiposPedido from "./tiposPedido";
import comunidadesAutonomas from "./comunidadesAutonomas";
import subpresentaciones from "./subpresentaciones";
import textosVentas from "./textosVentas";
import presentaciones from "./presentaciones";
import familias from "./familias";
import usuarioshijos from "./usuariosHijos";

export default combineReducers({
  articulos,
  articulo,
  pedidos,
  informes,
  errors,
  messages,
  auth,
  loader,
  portal,
  precio,
  listasCompra,
  descripcion,
  modalGenerica,
  pedidosAnteriores,
  pedidosPendientes,
  contacto,
  listadoArticulos,
  avisos,
  formasEnvio,
  domiciliosEnvio,
  almacenes,
  rutas,
  galeria,
  widgets,
  informesFiltroLov,
  provincias,
  estados,
  expedientes,
  tiposNif,
  banners,
  imagen,
  migas,
  agrupaciones,
  tiras,
  formasPago,
  modular,
  tiposPedido,
  comunidadesAutonomas,
  subpresentaciones,
  familias,
  textosVentas,
  presentaciones,
  usuarioshijos,
});
